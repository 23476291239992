import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

const operatingHours = process.env.REACT_APP_SHARE_PIN_HOURS
  ? JSON.parse(process.env.REACT_APP_SHARE_PIN_HOURS)
  : null;

export const isWithinOperatingHours = () => {
  if (!operatingHours) {
    console.error('No operating hours set');
    return false;
  }

  const { days, open, close, timezone: envTimezone } = operatingHours;

  // Log the current time and timezone
  const currentDateTime = dayjs().tz(envTimezone); // Use the provided timezone from .env file
  //console.log('Current Date and Time:', currentDateTime.format('MM/DD/YYYY, h:mm:ss A'));
  //console.log('Timezone being used:', envTimezone);

  // Get the current day of the week
  const currentDay = currentDateTime.format('dddd');
  //console.log('Current Day:', currentDay);

  // Check if today is within the allowed operating days
  if (!days.includes(currentDay)) {
    //console.log(`Today (${currentDay}) is not within operating days:`, days);
    return false;
  }

  // Helper function to convert a time string (e.g., "6:00 PM") into 24-hour format
  const convertTo24Hour = (timeStr) => {
    const [time, modifier] = timeStr.split(' ');
    let [hours, minutes] = time.split(':').map(Number);

    if (modifier === 'PM' && hours < 12) {
      hours += 12;
    } else if (modifier === 'AM' && hours === 12) {
      hours = 0; // Convert 12 AM to 0 for 24-hour format
    }

    return { hours, minutes };
  };

  // Convert open and close times to 24-hour format
  const openTimeObj = convertTo24Hour(open);
  const closeTimeObj = convertTo24Hour(close);

  // Set the time for opening and closing
  const openTime = currentDateTime.set('hour', openTimeObj.hours).set('minute', openTimeObj.minutes);
  const closeTime = currentDateTime.set('hour', closeTimeObj.hours).set('minute', closeTimeObj.minutes);

  // Log the parsed times for opening and closing
  // console.log('Opening Time:', openTime.format('h:mm A'));
  //console.log('Closing Time:', closeTime.format('h:mm A'));
  //console.log('Current Time:', currentDateTime.format('h:mm A'));

  // Compare current time with operating hours
  const isOpen = currentDateTime.isAfter(openTime) && currentDateTime.isBefore(closeTime);
  //console.log('Is Within Operating Hours:', isOpen);
  return isOpen;
};
