import React, { useState, useEffect } from 'react';
import { Modal, Box, Typography, Button, IconButton } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy'; // Add MUI copy icon
import { useTranslation } from 'react-i18next'; // For translations

const PinModal = ({ open, handleClose, jsonDiag }) => {
  const { t } = useTranslation();
  const [emailSent, setEmailSent] = useState(() => sessionStorage.getItem('emailSent') === 'true');
  const [pin, setPin] = useState(() => sessionStorage.getItem('userPin') || null);
  const [copySuccess, setCopySuccess] = useState(false); // State for showing copy success message

  useEffect(() => {
    // Generate the PIN if it doesn't exist when the modal opens
    if (open && !pin) {
      const generatedPin = Math.floor(100000 + Math.random() * 900000).toString();
      setPin(generatedPin);
      sessionStorage.setItem('userPin', generatedPin);
    }

    // Only send the email if message data is present and the email hasn't been sent yet
    if (open && jsonDiag && !emailSent && pin) {
      const sendEmail = async () => {
        const api_url = process.env.REACT_APP_API_URL; // Your API endpoint for sending emails
        try {
          const response = await fetch(api_url, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              jsonDiag,
              pin, // Include the generated PIN in the email
            }),
          });

          if (response.ok) {
            console.log('Email sent successfully');
            //console.log(jsonDiag); // Log the JSON data sent in the email
            setEmailSent(true);
            sessionStorage.setItem('emailSent', 'true'); // Store a flag in sessionStorage to ensure email is only sent once
          } else {
            console.error('Failed to send email:', response);
          }
        } catch (error) {
          console.error('Error sending email:', error);
        }
      };

      sendEmail();
    }
  }, [open, pin, jsonDiag, emailSent, setPin]);

  // Copy PIN to clipboard
  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(pin).then(() => {
      setCopySuccess(true); // Show success message
      setTimeout(() => setCopySuccess(false), 3000); // Hide after 3 seconds
    }).catch(err => {
      console.error('Failed to copy pin: ', err);
    });
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={{ padding: 4, backgroundColor: 'white', margin: '20% auto', width: '400px', borderRadius: '8px' }}>
        <Typography variant="h6">
          TU PIN: <strong>{pin || 'Generando...'}</strong>
          {/* Copy to Clipboard Icon */}
          <IconButton
            size="small"
            sx={{ marginLeft: 1 }}
            onClick={handleCopyToClipboard}
            aria-label={t('copy_to_clipboard')} // Accessible label for icon button
          >
            <ContentCopyIcon />
          </IconButton>
        </Typography>

        {/* Display the success message when copied */}
        {copySuccess && (
          <Typography variant="body2" sx={{ color: 'green', marginTop: 1 }}>
            {t('pin_copied_to_clipboard')} {/* Translated message */}
          </Typography>
        )}

        <Typography variant="body1" sx={{ marginTop: 2 }}>
          {t('remember_pin_message')}
        </Typography>
        <Button
          variant="contained"
          href={process.env.REACT_APP_CHAT_URL}
          target={'_blank'}
          sx={{ marginTop: 2 }}
          onClick={handleClose}
        >
          {t('chat_with_doctor')}
        </Button>
      </Box>
    </Modal>
  );
};

export default PinModal;