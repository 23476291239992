import React, { useState, useEffect } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import selectTheme from './components/selectTheme';
import CssBaseline from '@mui/material/CssBaseline';
import WelcomeScreen from './components/WelcomeScreen';
import Questionnaire from './components/Questionnaire';
import ErrorScreen from './components/errorScreen';  // Import a new error component

const receive_params = process.env.REACT_APP_RECEIVE_PARAMS === 'true'; // Convert string to boolean
const client = process.env.REACT_APP_CLIENT;
const theme = selectTheme(client);

function App() {
  const [showQuestionnaire, setShowQuestionnaire] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null); // For storing the error message
  const [params, setParams] = useState({}); // Store the parameters

  useEffect(() => {
    if (receive_params) {
      // Get parameters from URL
      const searchParams = new URLSearchParams(window.location.search);
      const idParam = searchParams.get('id');
      const dobParam = searchParams.get('dob');
      const genParam = searchParams.get('gen');
      const titParam = searchParams.get('tit');

      // Check if all parameters are received
      if (!idParam || !dobParam || !genParam || !titParam) {
        // If any parameter is missing, show an error
        const missingParams = [
          !idParam && 'ID',
          !dobParam && 'DOB',
          !genParam && 'GEN',
          !titParam && 'TIT',
        ].filter(Boolean).join(', ');
        setErrorMessage(`Missing required parameters: ${missingParams}`);
      } else {
        // If all parameters are received, set them and show the Questionnaire
        setParams({ id: idParam, dob: dobParam, gen: genParam, tit: titParam });
        setShowQuestionnaire(true);
      }
    }
  }, []);

  const handleContinue = () => {
    setShowQuestionnaire(true);
  };

  // If receive_params is true and there is an error, render ErrorScreen
  if (receive_params && errorMessage) {
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <ErrorScreen message={errorMessage} /> {/* Show error screen */}
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className="App">
        {showQuestionnaire ? (
          <Questionnaire {...params} /> /* Pass params to Questionnaire */
        ) : (
          <WelcomeScreen onContinue={handleContinue} />
        )}
      </div>
    </ThemeProvider>
  );
}

export default App;
